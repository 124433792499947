import type { RouteLocationNormalizedTyped } from 'vue-router';

/**
 * Checks if there is valid refund offers and redirect if not
 */
export default defineNuxtRouteMiddleware((to: RouteLocationNormalizedTyped) => {
    const localeRoute = useLocaleRoute();
    const refundStore = useRefundStore();

    // If there are no refund offers or the stored offers doesnt match the current booking, return to refund start
    if (!refundStore.refundOffers.length || refundStore.refundOffers.some(o => o.bookingId !== to.params.bookingId)) {
        return navigateTo(localeRoute({ name: 'esav-refund-step-1', params: { bookingId: to.params.bookingId } }));
    }
});
